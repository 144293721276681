<template>
  <tr>
    <td>
      <img class="uk-border-circle" width="80" :src="item.organizLogo" :alt="item.organizName"/>
    </td>
    <td><span>{{item.organizName}}</span>
    </td>
    <td>
      <span v-if="item.question_type == '1'">Multiple Choice(A-type)</span>
      <!--<span v-if="item.question_type == '2'">Multiple Choice(A-type)</span>-->
      <span v-if="item.question_type == '3'">Short Answer</span>
      <span v-if="item.question_type == '4'">Speaking</span>
      <span v-if="item.question_type == '5'">Multiple Choice(R-type)</span>
    </td>
    <td><p v-html="shortDescription"></p></td>
    <td>
      <button type="button"  class="uk-button uk-button-small uk-button-secondary">Detail</button>
    </td>
    <td>
      <div v-if="!item.free">
          <span
            v-if="!item.free && item.discount > 0"
            class="uk-label uk-label-danger uk-text-bold"
          >SALE</span>
        <del v-if="item.discount > 0" class="uk-text-meta uk-text-danger">{{item.price}} Point</del>
        <div class="uk-text-danger uk-text-meta">{{item.price -((item.price * item.discount)/100)}} Point
        </div>
      </div>
      <div class="tm-product-card-prices" v-else>
        <span class="uk-text-danger uk-text-meta">Free</span>
      </div>
    </td>
    <td>
      <div class="tm-product-card-add uk-margin-remove">
        <button type="button" @click="addItem"
          class="uk-button uk-button-primary tm-shine uk-button-small">
          Add
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
  import {mapActions} from 'vuex'

  export default {
    props: ['item', 'displayList'],
    data: function () {
      return {
        itemm: this.item,
        image: '',
        imagedata: null,
        classes: [
          {class: 'image'},
          {class: 'video'},
          {class: 'audio'},
          {class: 'stack'}
        ]
      }
    },
    methods: {
      ...mapActions(['updateCart']),
      addItem() {
        const order = {
          item: Object.assign({}, this.item),
          quantity: 1,
          isAdd: true,
          type: 'question'
        }
        this.updateCart(order)
      },
      displayType() {
        return this.classes[this.itemm.datatype].class
      }
    },
    computed: {
      shortDescription() {
        if (this.item.question && this.item.question.length > 50) {
          return this.item.question.substring(0, 50) + '...'
        } else {
          return this.item.question
        }
      }
    },
    filters: {},
    mounted() {
    }
  }
</script>

<style scoped>
</style>
