<template>
    <section class="uk-section uk-section-small uk-padding-remove">

      <page-header/>

      <div class="Home uk-container">
        <div class="uk-grid-medium uk-child-width-1-1 uk-grid uk-grid-stack" uk-grid>

          <div class="uk-text-center">
            <ul class="uk-breadcrumb uk-flex-center uk-margin-remove">
              <li v-for="(item, index) in sitemaps" :key="index" v-html="item"></li>
            </ul>
            <h1 class="uk-margin-small-top uk-margin-remove">{{title}}</h1>
<!--            <div class="uk-text-meta uk-margin-xsmall-top">-->
<!--              <span>-->
<!--                <span v-if="questions!=null">{{questions.length}}</span> -->
<!--              </span> -->
<!--            </div>-->
          </div>

          <div class="search">
            <form @submit.prevent="search">
              <div class="uk-grid-small uk-flex-center uk-grid" uk-grid>
                <div class="uk-width-expand uk-first-column">
                  <div class="uk-inline uk-width-1-1">
                    <span class="uk-form-icon uk-icon" uk-icon="search"></span>
                    <input
                      style="height: 42px;"
                      class="uk-input"
                      type="text"
                      v-model="searchval"
                      placeholder="Please type here the question you are looking for"
                      required>
                  </div>
                </div>
                <div>
                  <button type="submit" class="uk-button uk-button-primary"><span
                    uk-icon="icon: search; ratio: 1"></span></button>
                </div>
              </div>
            </form>
          </div>

          <div class="uk-grid-margin">
            <div class="uk-grid-medium uk-grid uk-grid-stack" uk-grid>
              <aside
                class="uk-width-1-4 tm-aside-column tm-filters uk-grid-match"
                id="filters"
                uk-offcanvas="overlay: true; container: false;"
              >
                <div class="uk-offcanvas-bar uk-padding-remove">
                  <div
                    class="uk-card uk-card-default uk-card-small uk-flex uk-flex-column uk-height-1-1"
                  >
                    <header class="uk-card-header uk-flex uk-flex-middle">
                      <div class="uk-grid-small uk-flex-1" uk-grid>
                        <div class="uk-width-expand">
                          <h3>{{$t("organization.dataQuestionList.filters")}}</h3>
                        </div>
                        <button type="button" class="uk-offcanvas-close"  uk-close></button>
                      </div>
                    </header>
                    <div
                      class="uk-margin-remove uk-flex-1 uk-overflow-auto"
                      uk-accordion="multiple: true; targets: &gt; .js-accordion-section"
                      style="flex-basis: auto"
                    >
                      <section class="uk-card-body js-accordion-section uk-open">
                        <h4 class="uk-accordion-title">{{$t("organization.dataQuestionList.cate")}}</h4>
                        <div class="uk-MyHistory.vueaccordion-content" aria-hidden="false">
                          <ul class="uk-list tm-scrollbox" v-if="Array.isArray(maincates)">
                            <app-category-item
                              v-for="item in maincates"
                              :item="item"
                              :key="item.id"
                              v-on:update-category="handleCategory"
                            ></app-category-item>
                          </ul>
                        </div>
                      </section>


                      <section class="uk-card-body js-accordion-section uk-open">
                        <h4 class="uk-accordion-title uk-margin-remove">
                          {{$t("organization.dataQuestionList.mType")}}
                          <span
                            class="tm-help-icon"
                            uk-icon="icon: question; ratio: .75;"
                            onclick="event.stopPropagation();"
                          ></span>
                          <div
                            class="uk-margin-remove"
                            uk-drop="mode: click;boundary-align: true; boundary: !.uk-accordion-title; pos: bottom-justify;"
                          >
                            <div
                              class="uk-card uk-card-body uk-card-default uk-card-small uk-box-shadow-xlarge uk-text-small"
                            ></div>
                          </div>
                        </h4>
                        <div class="uk-accordion-content">
                          <ul class="uk-list tm-scrollbox">
                            <li>
                              <input
                                class="tm-checkbox"
                                name="type"
                                id="cbvideo"
                                v-on:change="mediachange"
                                value="video"
                                type="checkbox"
                                v-model="mediatype"
                              />
                              <label for="cbvideo">
                                <span>
                                  {{$t("organization.dataQuestionList.video")}}
                                  <span class="uk-text-meta uk-text-xsmall">150</span>
                                </span>
                              </label>
                            </li>
                            <li>
                              <input
                                class="tm-checkbox"
                                name="type"
                                id="cbimage"
                                v-on:change="mediachange"
                                value="image"
                                type="checkbox"
                                v-model="mediatype"
                              />
                              <label for="cbimage">
                                <span>
                                  {{$t("organization.dataQuestionList.img")}}
                                  <span class="uk-text-meta uk-text-xsmall">150</span>
                                </span>
                              </label>
                            </li>
                            <li>
                              <input
                                class="tm-checkbox"
                                name="type"
                                id="cbstack"
                                v-on:change="mediachange"
                                value="stack"
                                type="checkbox"
                                v-model="mediatype"
                              />
                              <label for="cbstack">
                                <span>
                                  {{$t("organization.dataQuestionList.stack")}}
                                  <span class="uk-text-meta uk-text-xsmall">150</span>
                                </span>
                              </label>
                            </li>
                            <li>
                              <input
                                class="tm-checkbox"
                                name="type"
                                id="cbaudio"
                                v-on:change="mediachange"
                                value="audio"
                                type="checkbox"
                                v-model="mediatype"
                              />
                              <label for="cbaudio">
                                <span>
                                  {{$t("organization.dataQuestionList.audio")}}
                                  <span class="uk-text-meta uk-text-xsmall">150</span>
                                </span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </section>

                      <section class="uk-card-body uk-open js-accordion-section">
                        <h4 class="uk-accordion-title uk-margin-remove">{{$t("organization.dataQuestionList.point")}}</h4>
                        <div class="uk-accordion-content">
                          <div class="uk-text-left">
                            <ul class="uk-list tm-scrollbox">
                              <li>
                                <input
                                  class="tm-checkbox"
                                  name="type"
                                  id="cbfree"
                                  ref="rolefree"
                                  @change="checkfree($event)"
                                  type="checkbox"
                                  :v-model="isfree"
                                />
                                <label for="cbfree">
                                  <span>{{$t("organization.dataQuestionList.free")}}</span>
                                </label>
                              </li>
                            </ul>
                          </div>
                          <div
                            v-if="isfree == 'false'"
                            class="uk-grid-small uk-child-width-1-2 uk-text-small"
                            uk-grid
                          >
                            <div>
                              <div class="uk-inline">
                                <span class="uk-form-icon uk-text-xsmall">{{$t("organization.dataQuestionList.from")}}</span>
                                <input
                                  v-model="firstpoint"
                                  class="uk-input"
                                  type="text"
                                  placeholder="59"
                                />
                              </div>
                            </div>
                            <div>
                              <div class="uk-inline">
                                <span class="uk-form-icon uk-text-xsmall">{{$t("organization.dataQuestionList.to")}}</span>
                                <input
                                  v-model="lastpoint"
                                  class="uk-input"
                                  type="text"
                                  placeholder="6559"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <div class="uk-card-body">
                        <button
                          class="uk-button uk-button-default uk-width-1-1"
                          @click="resetfilter"
                        >
                          <span class="uk-margin-xsmall-right" uk-icon="icon: close; ratio: .75;"></span>Reset all
                          {{$t("organization.dataQuestionList.filters")}}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </aside>

              <div class="uk-width-expand">
                <div class="uk-grid-medium uk-child-width-1-1 uk-grid uk-grid-stack" uk-grid>
                  <div class="uk-first-column">
                    <div class="uk-width-expand uk-margin">
                      <div class="uk-position-relative" uk-slider>
                        <ul
                          class="uk-margin-left uk-margin-right uk-text-center uk-slider-items uk-child-width-auto uk-grid-small keywords"
                        >
                          <li v-for="(i,index) in 100" :key="index">
                          <span
                            class="uk-text-emphasis uk-link"
                          >{{i}}</span>
                          </li>

                        </ul>
                        <a
                          style="padding-top: 3px;"
                          class="uk-hidden-hover border-blue uk-text-emphasis uk-background-muted uk-position-left "
                          href="#"
                          uk-slider-item="previous"
                          uk-icon="icon:chevron-left;ratio: 1.2;"
                        ></a>
                        <a
                          style="padding-top: 3px;"
                          class="uk-hidden-hover border-blue uk-text-emphasis uk-background-muted uk-position-right"
                          href="#"
                          uk-slider-item="next"
                          uk-icon="icon:chevron-right;ratio: 1.2;"
                        ></a>
                      </div>
                    </div>
                    <div class="uk-card uk-card-default uk-card-small tm-ignore-container">

                      <div
                        class="uk-grid-collapse uk-child-width-1-1 uk-grid uk-grid-stack"
                        id="products"
                        uk-grid
                      >
                        <div class="uk-card-header">
                          <div class="uk-grid-small uk-flex-middle" uk-grid>
                            <div
                              class="uk-width-1-1 uk-width-expand@s uk-flex uk-flex-center uk-flex-left@s uk-text-small"
                            >
                              <span
                                style="margin-right:10px"
                                class="uk-flex uk-flex-middle"
                              >{{$t("organization.dataQuestionList.sortBy")}}:</span>

                              <div uk-form-custom="target: > * > span:first-child">
                                <select v-model="sortselected" @change="onChangeSort()">
                                  <option :value="norm">{{$t("organization.dataQuestionList.normal")}}</option>
                                  <option :value="news" >{{$t("organization.dataQuestionList.nData")}}</option>
                                  <option :value="earliest">{{$t("organization.dataQuestionList.oData")}}</option>
                                  <option :value="cheap">{{$t("organization.dataQuestionList.pUp")}}</option>
                                  <option :value="expensive">{{$t("organization.dataQuestionList.pDown")}}</option>
                                  <option :value="sale">{{$t("organization.dataQuestionList.sale")}}</option>
                                </select>
                                <button
                                  class="uk-button uk-button-default"

                                  tabindex="-1"
                                >

                                  <span uk-icon="icon: chevron-down"></span>
                                </button>
                              </div>
                            </div>
                            <div
                              class="uk-width-1-1 uk-width-auto@s uk-flex uk-flex-center uk-flex-middle"
                            >
                              <button
                                class="uk-button uk-button-default uk-button-small uk-hidden@m"
                                uk-toggle="target: #filters"
                              >
                                <span
                                  class="uk-margin-xsmall-right"
                                  uk-icon="icon: settings; ratio: .75;"
                                ></span>Filters
                              </button>
                              <div class="tm-change-view uk-margin-small-left">

                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="uk-grid-margin" v-if="questions!=null">
                          <div
                            v-if="questions.length > 0"
                            class="uk-grid-collapse uk-child-width-1-3 js-products-grid uk-grid tm-products-list">
                            <!-- :class="displayList" -->
                            <table
                              class="uk-table uk-table-hover uk-table-striped uk-table-responsive uk-text-center"
                              style="width:100%">
                              <thead>
                              <tr>
                                <th class="uk-text-center">{{$t("organization.dataQuestionList.orgalogo")}}</th>
                                <th class="uk-text-center">{{$t("organization.dataQuestionList.orga")}}</th>
                                <th class="uk-text-center">{{$t("organization.dataQuestionList.type")}}</th>
                                <th class="uk-text-center">{{$t("organization.dataQuestionList.ques")}}</th>
                                <th class="uk-text-center">{{$t("organization.dataQuestionList.detail")}}</th>
                                <th class="uk-text-center">{{$t("organization.dataQuestionList.price")}}</th>
                                <th class="uk-text-center">{{$t("organization.dataQuestionList.addCart")}}</th>
                              </tr>
                              </thead>
                              <tbody>
                              <app-question-item
                                v-for="question in questions"
                                :item="question"
                                :key="question.id"
                                :displayList="displayList"
                              ></app-question-item>
                              </tbody>
                            </table>
                          </div>
                          <div v-else>
                            <div v-if="isLoading==false" class="uk-alert-primary" uk-alert>
                              <a class="uk-alert-close" uk-close></a>
                              <p class="uk-text-center">{{$t("organization.dataQuestionList.eDatas")}}</p>
                            </div>
                          </div>

                        </div>

                        <div class="uk-grid-margin">
                          <button
                            class="uk-button uk-button-default uk-button-large uk-width-1-1"
                            style="border-top-left-radius: 0; border-top-right-radius: 0;"
                            @click.prevent="loadMore"
                            v-if="isLoadMoreBtn"
                          >
                            <span
                              class="uk-margin-small-right uk-icon"
                              uk-icon="icon: plus; ratio: .75;"
                            ></span>
                            <span>{{$t("organization.dataQuestionList.lMore")}}</span>
                          </button>

                        </div>

                        <div hidden class="uk-grid-margin">
                          <ul class="uk-pagination uk-flex-center">
                            <li class="uk-active">
                              <span>1</span>
                            </li>
                            <li>
                              <a href="#">2</a>
                            </li>
                            <li class="uk-disabled">
                              <span>…</span>
                            </li>
                            <li>
                              <a href="#">20</a>
                            </li>
                            <li>
                              <a href="#">
                                <span uk-pagination-next class="uk-pagination-next uk-icon"></span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <page-footer/>
    </section>

</template>

<script scoped>

  import {mapGetters} from 'vuex'
  import QuestionService from '@/services/QuestionService'
  import CategoryService from '@/services/CategoryService'
  import QuestionItem from '@/components/globitemviews/Answer/QuestionItem'
  import CategoryItem from '@/components/globitemviews/Category/QCategoryItem'
  import PageHeader from '@/components/Organizcmp/OrganizHeader.vue'
	const PageFooter = () => import("../Organizcmp/OrganizFooter.vue")
  import _ from 'lodash'

  export default {
    name: 'Home',
    components: {
      appQuestionItem: QuestionItem,
      appCategoryItem: CategoryItem,
      PageHeader,
      PageFooter
    },
    data() {
      return {
        searchval: "",
        answers: null,
        questions: null,
        displayList: 'tm-products-grid',
        currentPage: 0,
        pages: 0,
        isLoadMoreBtn: true,
        isLoadMoreFinish: false,
        isLoading: false,
        loaderColor: 'rgb(1, 176, 255)',
        loaderSize: '25px',
        vtype: 'Question',
        mediatype: [],
        sortselected: 'norm',
        firstpoint: '',
        lastpoint: '',
        maincates: null,
        reqparams: {},
        sitemaps: [],
        title: '',
        isfree: 'false'
      }
    },
    watch: {
      $route() {
        if (this.$route.query.q) {
          this.initdata()
        }
      },
      // eslint-disable-next-line no-unused-vars
      lastpoint: _.debounce(async function (value) {
        if (this.lastpoint !== '' && this.firstpoint !== '') {
          this.changetype()
        }
      }, 700),
      // eslint-disable-next-line no-unused-vars
      firstpoint: _.debounce(async function (value) {
        if (this.lastpoint !== '' && this.firstpoint !== '') {
          this.changetype()
        }
      }, 700)
    },
    created() {
      // refresh hiihed urlparamaas hamarch default utguudaa oorchiloh
      if (this.$route.query.sort) {
        this.sortselected = this.$route.query.sort
      }
      if (this.$route.query.mtype0) {
        this.mediatype[0] = this.$route.query.mtype0
      }
      if (this.$route.query.mtype1) {
        this.mediatype[1] = this.$route.query.mtype1
      }
      if (this.$route.query.mtype3) {
        this.mediatype[3] = this.$route.query.mtype3
      }
      if (this.$route.query.mtype4) {
        this.mediatype[4] = this.$route.query.mtype4
      }
      if (this.$route.query.datatype) {
        if (this.$route.query.datatype === 'Question') {
          this.vtype = 'Question'
        }
      }

      // end

      this.initdata()
    },
    async mounted() {
      this.loadMainCates()

      window.addEventListener('scroll', () => {
        this.bottom(this.bottomVisible())
        // console.log(this.bottomVisible())
      })
      // console.log(this.player)
    },
    methods: {
      async search() {
        if (this.searchval.length > 0)
          this.$router.push({
            name: 'questionmarket',
            query: {q: this.searchval}
          })
      },
      checkfree(e) {
        console.log(this.isfree)
        if (e.srcElement.checked) {
          // alert('hi')
          this.isfree = 'true'
        } else {
          this.isfree = 'false'
        }
        this.changetype()
      },
      resetfilter() {
        //  this.reqparams = {}

        this.answers = null
        this.questions = null
        this.currentPage = 0
        this.pages = 0
        this.isLoadMoreBtn = true
        this.isLoadMoreFinish = false
        this.isLoading = false

        // this.vtype = 'Data'
        this.mediatype = []
        this.sortselected = 'norm'
        this.firstpoint = ''
        this.lastpoint = ''
        this.reqparams = {}
        this.sitemaps = []
        this.title = 'Products'
        this.isfree = 'false'
        this.$refs.rolefree.checked = false

        this.setreqparam()
        this.reqparams['datatime'] = new Date().getTime()
        this.$router.push({name: 'questionmarket', query: this.reqparams})
        this.changetype()
      },

      initdata() {
        this.title = this.vtype
        this.isLoading = true
        this.answers = null
        this.questions = null
        this.currentPage = 0
        this.pages = 0
        this.isLoadMoreBtn = true
        this.isLoadMoreFinish = true
        this.isLoading = true

        this.setreqparam()
        if (this.$route.query.q) {
          this.reqparams['q'] = this.$route.query.q
        }
        if (this.$route.query.keyword) {
          this.reqparams['keyword'] = this.$route.query.keyword
        }

        const page = this.currentPage
        this.reqparams['page'] = page
        this.$router.push({query: this.reqparams})

        QuestionService.getQuestionsByPage(this.reqparams).then(res => {
          const {data} = res
          console.log(data)
          this.questions = data.content
          if (data.status == 200) {
            this.isLoadMoreBtn = false
            this.isLoadMoreFinish = true
            this.isLoading = false
            return
          }

          this.pages = res.totalPages
          this.currentPage = page
          this.isLoading = false
        })

      },
      onChangeSort() {
        this.initdata()
      },
      handleCategory() {
        this.changetype()
        // console.log(item.name)
      },
      setreqparam() {
        var i
        this.reqparams = {}
        for (i = 0; i < this.mediatype.length; i++) {
          this.reqparams['mtype' + i] = this.mediatype[i]
        }

        if (this.sortselected) {
          this.reqparams['sort'] = this.sortselected
        }

        if (this.lastpoint) {
          this.reqparams['lpoint'] = this.lastpoint
        }

        if (this.firstpoint) {
          this.reqparams['fpoint'] = this.firstpoint
        }
        if (this.isfree) {
          this.reqparams['isfree'] = this.isfree
        }
        if (this.vtype) {
          this.reqparams['datatype'] = this.vtype
        }

        this.sitemaps = []

        if (this.$route.params.catid) {
          this.reqparams['catid'] = this.$route.params.catid

          this.sitemaps[0] = `<a href="/organization/questionmarket/t/${
            this.$route.params.slug
            }-${this.$route.params.catid}">${this.$route.params.slug}</a>`
          this.title = this.$route.params.slug
        }
        if (this.$route.params.catid2) {
          this.reqparams['catid2'] = this.$route.params.catid2
          this.sitemaps[1] = `<a href="/organization/questionmarket/t/${
            this.$route.params.slug
            }-${this.$route.params.catid}/${this.$route.params.slug2}-${
            this.$route.params.catid2
            }">${this.$route.params.slug2}</a>`
          this.title = this.$route.params.slug2
        } else {
          this.sitemaps = []
        }
        if (this.$route.params.catid3) {
          this.reqparams['catid3'] = this.$route.params.catid3
          this.sitemaps[2] = `<a href="/organization/questionmarket/t/${
            this.$route.params.slug
            }-${this.$route.params.catid}/${this.$route.params.slug2}-${
            this.$route.params.catid3
            }/${this.$route.params.slug3}-${this.$route.params.catid2}">${
            this.$route.params.slug3
            }</a>`
          this.title = this.$route.params.slug3
        }
      },
      mediachange() {
        //
        console.log('mediachange')
        this.changetype()
      },
      changetype() {
        // alertalert(this.vtype)
        this.title = this.vtype
        this.answers = null
        this.questions = null
        this.currentPage = 0
        this.pages = 0
        this.isLoadMoreBtn = true
        this.isLoadMoreFinish = true
        this.isLoading = true

        this.setreqparam()

        const page = this.currentPage
        this.reqparams['page'] = page
        this.$router.push({query: this.reqparams})

        QuestionService.getQuestionsByPage(this.reqparams).then(res => {
          const {data} = res
          console.log(data)
          this.questions = data.content
          if (!data.content) {
            this.isLoadMoreBtn = false
            this.isLoadMoreFinish = true
            this.isLoading = false
            return
          }

          this.pages = res.totalPages
          this.currentPage = page
          this.isLoading = false
        })

      },
      changeDisplay(isList) {
        if (isList) {
          this.displayList = 'tm-products-list'
        } else {
          this.displayList = 'tm-products-grid'
        }
      },
      bottomVisible() {
        const scrollY = window.scrollY
        const visible = document.documentElement.clientHeight
        const pageHeight = document.documentElement.scrollHeight
        const bottomOfPage = visible + scrollY >= pageHeight
        return bottomOfPage || pageHeight < visible
      },

      loadMore() {

        this.loadMoreQuestions()

      },

      loadMoreQuestions() {
        this.isLoading = true

        const page = this.currentPage + 1
        this.reqparams['page'] = page
        QuestionService.getQuestionsByPage(this.reqparams).then(res => {
          const {data} = res

          console.log(data)
          if (!data.content.length) {
            // alert("hi")
            this.isLoadMoreBtn = false
            this.isLoadMoreFinish = true
            this.isLoading = false
            return
          }

          data.content.forEach(question => {
            this.questions.push(question)
          })
          this.pages = res.totalPages
          this.currentPage = page
          this.isLoading = false
        })
      },
      loadMainCates() {
        CategoryService.getMainCategories().then(res => {
          const {data} = res
          // console.log(data)
          this.maincates = data
        })
      },
      bottom() {
        // console.log(`orlooooo ${bottom} ${this.isLoadMoreFinish}`)
        // if (bottom && !this.isLoadMoreFinish) {
        //   if (this.vtype === 'Data') {
        //     if (!this.isLoadMoreFinish) {
        //       this.loadMoreAnswers()
        //     }
        //   } else {
        //     if (!this.isLoadMoreFinish) {
        //       this.loadMoreQuestions()
        //     }
        //   }
        // }
      }
    },
    computed: {
      ...mapGetters(['isProductLoading'])
    }
  }
</script>

<style scoped>

  .keywords {
    margin: 0;

  }
  .uk-list > li > :last-child {
    margin: 10px;
  }
  .keywords li span {
    background: #fff;
    border: 1px solid #eee;
    padding: 4px;
    border-radius: 2px;
    text-decoration: none;
    display: block;
  }

  .keywords li span:hover {
    text-decoration: none;
    border: 1px solid #0e6dcd;
    transition: 0.2s;
    -webkit-transition: 0.2s;
  }
  .uk-accordion-title{
    margin: 10px !important;
  }
</style>
