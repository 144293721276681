var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{attrs:{"id":_vm.item.id}},[_c('router-link',{class:{
        'active': _vm.currentCategory == _vm.item.name
      },attrs:{"tag":"a","to":{ name: 'qcategory',params: { slug: _vm.slugify(_vm.item.name),
                                      catid: _vm.item.id}}},nativeOn:{"click":function($event){return _vm.clickCategory(_vm.item)}}},[_vm._v(_vm._s(_vm.item.name))]),(_vm.subcates!=null&&_vm.subcates.parent_id == _vm.item.id)?_c('ul',{staticClass:"uk-list uk-list-small"},_vm._l((_vm.subcates.cates),function(sitem,sindex){return _c('li',{key:sindex,attrs:{"id":sitem.id}},[_c('router-link',{class:{
        'active': _vm.currentCategory == sitem.name
      },attrs:{"tag":"a","to":{ name: 'qcategorys',params: {slug: _vm.slugify(_vm.item.name),
                                      catid: _vm.item.id, slug2: _vm.slugify(sitem.name),
                                      catid2: sitem.id}}},nativeOn:{"click":function($event){return _vm.clickCategory(sitem)}}},[_vm._v(_vm._s(sitem.name))]),(_vm.subcates2!=null&&_vm.subcates2.parent_id == sitem.id)?_c('ul',{staticClass:"uk-list uk-list-small"},_vm._l((_vm.subcates2.cates),function(s2item,s2index){return _c('li',{key:s2index,attrs:{"id":s2item.id}},[_c('router-link',{class:{
        'active': _vm.currentCategory == sitem.name
      },attrs:{"tag":"a","to":{ name: 'qcategoryss',params: {slug: _vm.slugify(_vm.item.name),
                                      catid: _vm.item.id, slug2: _vm.slugify(sitem.name),
                                      catid2: sitem.id, slug3: _vm.slugify(s2item.name),
                                      catid3: s2item.id}}},nativeOn:{"click":function($event){return _vm.clickCategory(s2item)}}},[_vm._v(_vm._s(s2item.name))])],1)}),0):_vm._e()],1)}),0):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }